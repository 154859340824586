import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomeOne from "./pages/HomeOne";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import ScrollToTop from "react-scroll-to-top";
import About from "./pages/About";
import Service from "./pages/Service";
import ServiceDetails from "./pages/ServiceDetails";
import Blog from "./pages/Blog";
import BlogDetails from "./pages/BlogDetails";
import Pricing from "./pages/Pricing";
import Faq from "./pages/Faq";
import Contact from "./pages/Contact";
import RouteScrollToTop from "./elements/RouteScrollToTop";

import B2Bshipping from "./pages/B2Bshipping";
import Ecom from "./pages/Ecom";
import Personal from "./pages/Personal";
import AboutStry from "./pages/AboutStry";
import Track from "./pages/Track";
import Serviciblity from "./pages/Serviciblity";
import ServiceOne from "./components/ServiceOne";
import { HelmetProvider } from "react-helmet-async";
function App() {
  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);
  return (
    <HelmetProvider>
      <BrowserRouter>
        <RouteScrollToTop />
        <Routes>
          <Route exact path='/' element={<HomeOne />} />
          <Route exact path='/part-load-truck' element={<About />} />
          <Route exact path='/aboutstry' element={<AboutStry />} />
          <Route exact path='/ecommerce' element={<Service />} />
          <Route exact path='/international' element={<ServiceDetails />} />
          <Route exact path='/full-truck-load' element={<Blog />} />
          <Route exact path='/warehousing' element={<BlogDetails />} />
          <Route exact path='/domestic' element={<Pricing />} />
          <Route exact path='/b2b' element={<B2Bshipping />} />
          <Route exact path='/ecom' element={<Ecom />} />
          <Route exact path='/service' element={<ServiceOne />} />
          <Route exact path='/personal' element={<Personal />} />
          <Route exact path='/faq' element={<Faq />} />
          <Route exact path='/contact' element={<Contact />} />
          <Route exact path='/track' element={<Track />} />
          <Route exact path='/serviciblity' element={<Serviciblity />} />
        </Routes>
        <ScrollToTop smooth color='#ffc94a' />
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
