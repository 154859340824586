import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
import SEO from "./SEO";

const BreadCrumbsolu = React.lazy(() => import("../components/BreadCrumbsolu"));
const ContactOne = React.lazy(() => import("../components/ContactOne"));
const FooterBottomOne = React.lazy(() =>
	import("../components/FooterBottomOne")
);
const WhyChooseUsTwo = React.lazy(() =>
	import("../components/WhyChooseUsOne")
);
const AboutSol3 = React.lazy(() => import("../components/AboutSol3"));
const AboutThree = React.lazy(() => import("../components/AboutThree"));
const FooterOne = React.lazy(() => import("../components/FooterThree"));
const NavbarOne = React.lazy(() => import("../components/NavbarFour"));
const SkillSix = React.lazy(() => import("../components/SkillSix"));
const ServiceOneAll = React.lazy(() => import("../components/ServiceOneAll"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));


const Personal = () => {
	return (
		<>
			<Fragment>
				<Suspense fallback={<Preloader />}>
					<SEO title={"Grc Logistics || Personal Shipping"} link={"https://www.grclogistics.com/personal"} />
					{/* Search Popup */}
					<SearchPopup />

					{/* Navbar One */}
					<NavbarOne />

					{/* BreadCrumbsolu */}
					<BreadCrumbsolu title={"Personal Shipping"} />

					{/* Service One All */}
					<div className='pd-top-120 pd-bottom-120'>
						<AboutSol3 />
					</div>
					{/* Blog Details Inner */}
					{/* <BlogDetailsInner /> */}
					<WhyChooseUsTwo />


					{/* Footer One */}
					<FooterOne />

					{/* Footer Bottom One */}
				</Suspense>{" "}
			</Fragment>
		</>
	)
}

export default Personal