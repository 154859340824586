import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
import SEO from "./SEO";
// import WhyChooseUsTwo from "../components/WhyChooseUsTwo";
const BreadCrumbAbout = React.lazy(() => import("../components/BreadCrumbAbout"));
const CounterOne = React.lazy(() => import("../components/CounterOne"));
const WhyChooseUsTwo = React.lazy(() => import("../components/WhyChooseUsTwo"));
const FooterBottomOne = React.lazy(() =>
	import("../components/FooterBottomOne")
);
const ServiceOne = React.lazy(() => import("../components/ServiceOne"));
const AboutOne = React.lazy(() => import("../components/AboutOne"));
const FooterOne = React.lazy(() => import("../components/FooterThree"));
const NavbarOne = React.lazy(() => import("../components/NavbarFour"));
const PartnerOne = React.lazy(() => import("../components/PartnerOne"));
const SkillOne = React.lazy(() => import("../components/SkillOne"));
const TeamOne = React.lazy(() => import("../components/TeamOne"));
const TestimonialTwo = React.lazy(() => import("../components/TestimonialTwo"));
const VideoAreaOne = React.lazy(() => import("../components/VideoAreaOne"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));

const AboutStry = () => {
	return (
		<>
			<Fragment>
				<Suspense fallback={<Preloader />}>
					<SEO title={"Grc Logistics || About"} link={"https://www.grclogistics.com/aboutstry"} />
					{/* Search Popup */}
					<SearchPopup />

					{/* Navbar One */}
					<NavbarOne />

					{/* Breadcrumb */}
					<BreadCrumbAbout title={"ABOUT US "} />

					{/* About One */}
					{/* <div className='pd-top-120 pd-bottom-120'>
            <AboutOne />
          </div> */}
					<div className='pd-top-120 pd-bottom-120'>
						<AboutOne />
					</div>
					{/* Counter One */}

					{/* <div className='fact-area' style={{ background: "#f9f9f9" }}>
            <CounterOne />
          </div> */}

					{/* Skill One */}
					<ServiceOne />
					<WhyChooseUsTwo />

					{/* About One */}





					{/* Video Area One */}
					{/* <VideoAreaOne /> */}

					{/* Team One */}
					{/* <div className='pd-bottom-80'>
            <TeamOne />
          </div> */}

					{/* Testimonial Two */}
					{/* <TestimonialTwo /> */}

					{/* Partner One */}
					{/* <PartnerOne /> */}

					{/* Footer One */}
					<FooterOne />

					{/* Footer Bottom One */}

				</Suspense>
			</Fragment>
		</>
	)
}

export default AboutStry