import React from 'react'
import { Helmet } from 'react-helmet'

const SEO = ({ title, link }) => {
	return (
		<Helmet>
			<meta charSet='utf-8' />
			<title>{title}</title>
			<meta
				name="keywords"
				content="GRC , Grclogistics , logistics grc , grc"
			/>
			<meta name="author" content="GRC" />
			<meta name="robots" content="index, follow" />
			<meta
				name="description"
				content="Discover GRC Logistics – your trusted partner in efficient courier and logistics management. Offering streamlined solutions for parcel tracking, delivery management, and operational excellence. Simplify your logistics with GRC Logistics today!"
			/>
			<link rel="canonical" href={link} />
		</Helmet>
	)
}

export default SEO