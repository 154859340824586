import React, { Fragment, Suspense, useState } from "react";
import Preloader from "../elements/Preloader";
import { pinCodeList } from "../data/pincodeList"
import ServiceTable from "../components/ServiceTable";
import SEO from "./SEO";
const BreadCrumbAbout = React.lazy(() => import("../components/BreadCrumbAbout"));
const RequestQuoteThree = React.lazy(() => import("../components/RequestQuoteThree"));
const FooterBottomOne = React.lazy(() =>
	import("../components/FooterBottomOne")
);
const WhyChooseUsTwo = React.lazy(() =>
	import("../components/WhyChooseUsTwo")
);
const AboutThree = React.lazy(() => import("../components/AboutThree"));
const FooterOne = React.lazy(() => import("../components/FooterThree"));
const NavbarOne = React.lazy(() => import("../components/NavbarFour"));
const SkillSix = React.lazy(() => import("../components/SkillSix"));
const ServiceOneAll = React.lazy(() => import("../components/ServiceOneAll"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));

const Serviciblity = () => {
	const [pinCode, setPinCode] = useState("")
	const [pinCodeDetail, setPinCodeDetail] = useState({})
	const handleCheckPinCode = () => {
		console.log("click")
		if (pinCode.length === 0 || pinCode.length !== 6) {
			alert('Please enter a valid Pin Code')
		}
		else {
			console.log("hii")
			pinCodeList.map((pin) => {
				if (pin.Pin === Number(pinCode)) {
					setPinCodeDetail(pin)
				}
			})
			setPinCode("")
		}
	}
	return (
		<>
			<Fragment>
				<Suspense fallback={<Preloader />}>
					<SEO title={"Grc Logistics || Servicibility"} link={"https://www.grclogistics.com/serviciblity"} />
					{/* Search Popup */}
					<SearchPopup />

					{/* Navbar One */}
					<NavbarOne />

					{/* BreadCrumbAbout */}
					{/* <BreadCrumbAbout title={"Check Servicibility"} /> */}

					{/* Service One All */}
					<div className='mg-top-120 pd-bottom-60'>
						<RequestQuoteThree handleCheckPinCode={handleCheckPinCode} pinCodeDetail={pinCodeDetail} pinCode={pinCode} setPinCode={setPinCode} />
					</div>

					{/* <div className='pd-top-120 pd-bottom-120'>
						<AboutThree />
					</div> */}

					{/* Footer One */}
					<FooterOne />

					{/* Footer Bottom One */}
				</Suspense>{" "}
			</Fragment>
		</>
	)
}

export default Serviciblity