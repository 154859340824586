import React, { Fragment, Suspense } from "react";
import Preloader from "../elements/Preloader";
import SEO from "./SEO";
const BlogDetailsInner = React.lazy(() =>
  import("../components/BlogDetailsInner")
);
const Breadcrumb = React.lazy(() => import("../components/Breadcrumb"));
const WhyChooseUsTwo = React.lazy(() =>
  import("../components/WhyChooseUsOne")
);
const SkillFour = React.lazy(() => import("../components/SkillFour"));
const SkillsThree = React.lazy(() => import("../components/SkillsThree"));
const AboutThree = React.lazy(() => import("../components/AboutOne"));
const FooterOne = React.lazy(() => import("../components/FooterThree"));
const NavbarOne = React.lazy(() => import("../components/NavbarFour"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));
const BlogDetails = () => {
  return (
    <>
      <Fragment>
        <Suspense fallback={<Preloader />}>
          <SEO title={"Grc Logistics || Warehousing"} link={"https://www.grclogistics.com/warehousing"} />
          {/* Search Popup */}
          <SearchPopup />

          {/* Navbar One */}
          <NavbarOne />

          {/* Breadcrumb */}
          <Breadcrumb title={"WAREHOUSING"} />
          <SkillsThree />
          {/* Blog Details Inner */}
          {/* <BlogDetailsInner /> */}
          <WhyChooseUsTwo />
          <div className='pd-top-120 pd-bottom-120'>
            <AboutThree />
          </div>

          {/* Footer One */}
          <FooterOne />

          {/* Footer Bottom One */}

        </Suspense>
      </Fragment>
    </>
  );
};

export default BlogDetails;
